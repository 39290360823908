.offcanvas,
.offcanvas-xxl,
.offcanvas-xl,
.offcanvas-lg,
.offcanvas-md,
.offcanvas-sm {
    --bs-offcanvas-zindex: 1045;
    --bs-offcanvas-width: 250px;
    --bs-offcanvas-padding-x: 3rem;
    --bs-offcanvas-padding-y: 2rem;
    --bs-offcanvas-color: rgb(61, 64, 51);
    --bs-offcanvas-bg: rgb(239, 237, 232);
    --bs-offcanvas-border-width: 0px;
    --bs-offcanvas-border-color: rgb(61, 64, 51);
    --bs-offcanvas-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}